const data = {
    personal_details: {
        picture: "profile-pic.jpeg",
        name: "Nithish Reddy Yalaka",
        github: "https://github.com/NithishReddyNY",
        github_short: "NithishReddyNY",
        linkedin: "https://www.linkedin.com/in/nithishreddy",
        linkedin_short: "nithish-reddy",
        email: "careers.nithish@gmail.com",
        location: "Montreal, Quebec, Canada",
        highest_codechef_rating: "0",
        description: [
            "‣ Imagine a world where data flows like a river, constantly shaping and transforming the landscape. That's the world I live in, and I love every minute of it. I'm Nithish Reddy, a Data and MLOps Engineer with a passion for crafting solutions that move mountains.",
            "‣ With close to 6 years of experience under my belt, I've traveled the world of tech, working in industries such as Telecom, Retail, and Healthcare, always leaving my mark with my expertise in Data Engineering and Cloud computing. I'm fluent in revolutionary services like BigQuery, Vertex AI, and Dataflow.",
            "‣ But it's not just my technical skills that set me apart. I'm also a teacher at heart and have a passion for sharing my knowledge with others. I've mentored many up-and-coming data engineers and conducted workshops that have inspired countless others.",
            "‣ When I'm not working my magic, you'll find me cheering for my favorite football team(FC Barcelona 🔴🔵), reading a good book, playing a strategic game of chess, or putting pen to paper and writing about my latest adventures in the world of data.",
            "‣ So if you're looking for a data engineer who's equal parts tech-savvy and creative, look no further. Let's join forces and turn data into a beautiful work of art."
        ],
        highlights: [
            "Google Cloud",
            "Data Engineering",
            "BigData",
            "BigQuery",
            "Vertex AI",
            "Machine Learning",
            "AWS",
            "Google Looker",
            "Apache Airflow",
            "ETL",
            "Tensorflow",
            "Neural Networks"
        ]
    },
    experience: [
        {
            company: "Centennial College",
            location: "Toronto, ON, Canada",
            title: "Instructor",
            start_date: "Jan, 2023",
            end_date: "Present",
            description: "Lecturer and curriculum designer of the experiential learning course - Business & Entrepreneurship, designed for Software Engineering. Also, teaching Cloud, ML/AI, Data Engineering Courses",
            logo: "logos/Centennial-logo.png"
        },
        {
            company: "DynPro",
            location: "Toronto, ON, Canada (Remote)",
            title: "Sr. Data and MLOps Engineer",
            start_date: "June, 2022",
            end_date: "Present",
            description: "Building End - End ML models on Vertex AI, architecting and developing multiple Cloud Transformation and Migration projects",
            logo: "logos/dynpro.jpeg" 
        },
        {
            company: "Badal.io",
            location: "Toronto, ON, Canada (Remote)",
            title: "Sr. Data Engineer",
            start_date: "October, 2021",
            end_date: "June, 2022",
            description: "Built data pipelines on Google Cloud with Apache Airflow as ETL and orchestratestion using GCS as data lake and BigQuery as data warehouse. Data visualization and Business Intelligence using Google Looker",
            logo: "logos/Badal.io.png"
        },
        {
            company: "Tata Consultancy Services",
            location: "Hyderabad, India",
            title: "Data Engineer",
            start_date: "June, 2017",
            end_date: "Feb, 2021",
            description: "Built ETL data pipelines on Google Cloud with data fusion using GCS as data lake and BigQuery as data warehouse. Orchestrated pipelines with Apache Airflow(Cloud Composer)",
            logo: "logos/tcs.png"
        },
        {
            company: "DRDO - Internship",
            location: "Hyderabad, India",
            title: "Design Engineer",
            start_date: "April, 2016",
            end_date: "July, 2016",
            description: "SerDes (Serializer and Deserializer) chip design using Verilog HDL and FPGA.",
            logo: "logos/drdo-logo.jpeg"
        },
    ],
    education: [
        {
            institution: "Concordia University, Montreal",
            logo: "logos/Concordia-University.png",
            type: "College",
            course: "Information Systems Security (Majors in Cloud and Blockchain)",
            short_course: "Masters of Engineering",
            start_year: "2021",
            end_year: "2022",
            Positions_held: [
                "Director - Engineering and Computer Science, Graduate Student Association, Concordia University",
                "Chief Returning Officer(CRO) - ECSGA, Concordia University",
                "Representing Graduate Students at Concordia University's Student Tribunal Pool",
                "Chief Editor and Designer for Slate IMPACT"
            ],
            awards: [
                "Academic Excellence Award",
                "Appointed as the Chief Returning Officer for 2021-2022 General Elections, ECSGA",
                "Elected as the Director - Engineering and Computer Science Department, Graduate Students Association'"
            ]
        },
        {
            institution: "GITAM University",
            logo: "logos/GITAM-logo.jpeg",
            type: "College",
            course: "Electronics and Communication Engineering",
            short_course: "Bachelors of Engineering",
            start_year: "2013",
            end_year: "2017",
            awards: [
                "Winner of Land Rover Competition - GUSAC 2015, built and operated the Land Rover",
                "Winner of Tech Expo - 2015, Implementation of Tesla Coil",
            ]
        },

    ],
    courses: [
        {
            title: "Google Cloud Certified Professional Data Engineer",
            provider: "Google Cloud",
            certificate_link: "https://www.credential.net/51af6336-db0f-464e-9be0-6e5e0a832116"
        },
        {
            title: "Google Cloud Certified Cloud Digital Leader",
            provider: "Google Cloud",
            certificate_link: "https://www.credential.net/c6be7901-e434-4e2d-abc0-43a6157dfba8",
        },
        {
            title: "AWS Certified Cloud Digital Leader",
            provider: "Google Cloud",
            certificate_link: "https://www.credential.net/c6be7901-e434-4e2d-abc0-43a6157dfba8",
        },
        {
            title: "Apache Airflow Fundamentals",
            provider: "Astronomer",
            certificate_link: "https://www.youracclaim.com/badges/f24b1328-4cad-4d40-837a-227c568cd603?source=linked_in_profile"
        },
        {
            title: "Google Cloud Certified Professional Cloud Architect",
            provider: "Google Cloud",
            description: ["In Progress"]
        },
        {
            title: "DAG Authoring for Apache ",
            provider: "Astronomer",
            certificate_link: "https://www.credly.com/badges/b4aed6e1-59db-41bc-824c-ee14411c5a24?source=linked_in_profile"
        },
        {
            title: "Data Engineering, Big Data, and Machine Learning on GCP Specialization",
            provider: "Coursera",
            certificate_link: "https://www.coursera.org/account/accomplishments/specialization/certificate/PN73PRQ7E23A"
        },
        {
            title: "Cloud Engineering with Google Cloud",
            provider: "Coursera",
            certificate_link: "https://www.coursera.org/account/accomplishments/specialization/certificate/SB2MXZYKAAP5"
        },
        {
            title: "McGill Personal Finance Essentials",
            provider: "McGill University - Desautels Faculty of Management",
            certificate_link: "637486253812976795"
        }
    ],
    projects: [
        {
            title: "Sentiment Analysis on Social Engagement Platform",
            description: ["Developed for Morgan Stanley - Code to give hackathon",
                            "Performs Sentiment analysis on users post using Natural Language Processing",
                        "Uses Google Analytics to measure user activity on the platform",
                    "SEO optimized application built on Django framework"],
            tools: [
                "Python",
                "Django",
                "Heroku",
                "Postgres",
                "NLP",
                "HTML/CSS/JS",
                "AWS S3"
            ],
            github: "https://github.com/iSriBalaji/Code-To-Give-Mavericks",
            check_out: "https://atlanta-mission.herokuapp.com/"
        },
        {
            title: "Clinicon - Medical Assistance for Rural People",
            description: ["Full stack desktop application to predict diseases with greatest likelihood without any human intervention",
                        "Uses Google Speech to Text API convert patients speech to text and eSpeak synthesizer to convert text to speech",
                        "Patent No: 202041009208 | Intellectual Property India"],
            tools: [
                "Python",
                "Firebase",
                "UI - tkinter",
                "Classification",
                "Raspberry Pi",
            ],
            github: "https://github.com/iSriBalaji/Clinicon-Medical-Assistant",
            check_out: "https://www.youtube.com/watch?v=pA6wOhfTQgU&ab_channel=VelammalItechECE"
        },
        {
            title: "911 – What & How Well Do They Serve",
            description: ["Performed EDA to analyze and visualize uncovered structure of the New York state’s 911 dataset",
                        "Modeled regression to predict response time of future crimes and classified incidents by logistic regression with accuracy of 83%",
                        ],
            tools: [
                "Python",
                "SQLite",
                "Regression",
                "Classification",
                "Data Visualization",
            ],
            github: "https://github.com/iSriBalaji/911-Analysis",
            check_out: "https://docs.google.com/document/d/1-IknBpRzrvBQYOJO4tJZJ7_5HUE7n4qZ0NDiaCdMbHA/edit"
        },
    ],
    clubs: [
        {
            name: "Visualizing Google Cloud: 101 Illustrated References for Cloud Engineers and Architects",
            logo: "logos/Visualizing Google Cloud.jpeg"
        },
        {
            name: "Rich Dad Poor Dad by Robert Kayosaki",
            logo: "logos/richdad-poordad.png"
        },
        {
            name: "Mahabharata",
            logo: "logos/mahabharata.jpeg"
        },
        {
            name: "And Then There Were None",
            logo: "logos/and-then-there-were-none-agatha-christie.jpeg"
        },
        {
            name: "The Subtle Art of Not Giving a F*ck",
            logo: "logos/tsangaf.jpeg"
        },
        {
            name: "Murder of Roger Ackroyd",
            logo: "logos/roger ackroyd.jpeg"
        }
    ],
    skills: [
        {
            title: "Tools and Technologies",
            list: ["Postman", "Jupyter Notebooks", "Terraform", "ChatGPT", "Git", "GitHub", "Jenkins", "Jira", "Confluence", "SharePoint", "Google Colab", "ChatGPT"]
        },
        {
            title: "Data Science & ML",
            list: ["Tensorflow", "Vertex AI", "Feature Engineering", "Data preprocessing", "Model Evaluation", "pandas", "numpy", "CNN", "RNN", "NLP", "Visualization"]
        },
        {
            title: "Data Engineering",
            list: ["Hadoop Ecosystem", "Python", "ETL(Datafusion, Matillion, Dataflow)", "Snowflake", "Apache Airflow", "Google Looker", "AWS EMR", "Oracle SQL"]
        },
        {
            title: "Cloud Products",
            list: ["Google Cloud Storage", "BigQuery", "BigTable", "Composer(Airflow)", "DataFlow", "Vertex AI", "Data Studio", "GKE", "Compute Engine", "Cloud Healthcare Engine","Cloud Run", "Cloud Functions", "S3", "EC2", "Lambda"]
        }
    ],
    programming_achievements: [
        {
            title: "All India Rank 58 in National Creative Aptitude Test(NCAT)",
            description: "The NCAT is designed to sensitize the students to the domain of creative thinking and motivate them to read, learn and practice the same.",
            date: "2019"
        },
        {
            title: "Winner - Code to Give Hackathon (Morgan Stanley)",
            description: "Developed an SEO-optimized full-stack social engagement platform to connect stakeholders of an NGO (Atlanta Mission) with its clients. Appreciated with $500 prize",
            date: "2022"
        },
        {
            title: "Finalist - Smart India Hackathon 2020",
            description: "Selected as top 5 among thousands of teams all around India",
            date: "2020"
        },
        {
            title: "Best Paper Award - International Conference on Research Advancements & Challenges in Engineering Sciences (ICRACE'20)",
            description: "Received Best Paper Award for presenting a prototype for Social Security along with simulations.",
            date: "2020"
        },
        {
            title: "Winner of Design Your Destiny'2019 (Electrical Stream)Won Codechef DSA Certification Scholarship for excellent performance in their monthly challenge",
            description: "Won the hardware hackathon among 5000 teams all over India by clearing multiple rounds of preseantaions and demos over the period of 3 months. Appreciated with $500 prize",
            date: "2019"
        },
        {
            title: "Finalist of Buffalo Startup Weekend 2022 and volunteering for Startup Week & Startup Coffee",
            date: "2022"
        },
        {
            title: "Won in 5 Project fests and 7 Paper presentations across India",
            date: "2017-2020"
        }
    ]
}

export default data;